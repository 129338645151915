.banner-area {
    position: relative;
    justify-content: center;
    color: #fff;
    text-align: center;
    height: 600px;
    box-sizing: border-box;
}

.banner-item {
    height: 600px;
}

.banner-text {
    position: absolute;
    top: 32%;
    left: 12.5%;
    color: #fff;
    text-align: left;
}

.cropper {
    clip-path: polygon(0% 0%, 100% 0%, 100% 84%, 50% 100%, 0% 84%);
    height: 100%;
    width: 100%;

}


@media(max-height:670px) {
    .cropper {
        clip-path: none;
    }

    .banner-item {
        height: 100vh;
    }

    .banner-area {
        height: 100vh;
    }
}

.banner-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0px;
    left: 0px;
}

.banner-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
}

.banner-content h1 {
    color: #FFF;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.banner-content p {
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.90);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}