.news-area {
    margin-top: 40px;
}

.news-area__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.news-area__content__title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 16px;
    font-size: 40px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.88px;
}

.news-area__content__title a {
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.news-area__content__subtitle {
    font-size: 24px;
    font-weight: 500;
    color: #757575;
    line-height: 150%;
    letter-spacing: -0.456px;
}

.news-area__content__list {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.news-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.news-item__content {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 4px;
    color: #1E1E1E;
    font-family: Noto Sans KR;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.22px;
    text-decoration: none;
}

.news-item__timestamp {
    font-size: 18px;
    font-weight: 500;
    color: #1E1E1E;
}