.supports-area {
    margin-top: 80px;
    margin-bottom: 108px;
}

.supports-area__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.supports-area__content__title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 16px;
    font-size: 40px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.88px;
}

.supports-area__content__title a {
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.supports-area__content__subtitle {
    font-size: 24px;
    font-weight: 500;
    color: #757575;
    line-height: 150%;
    letter-spacing: -0.456px;
}

.supports-area__content__list {
    margin-top: 42px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.supports-area__content__list .box {
    width: 150px;
    height: 150px;
    border-radius: 20px;
}

.supports-area__content__list .profile {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width:700px){
    .container{
        flex-direction: column;
        align-content: space-evenly;}
}