.services-area {
    margin-top: 80px;
}

.services-area__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.services-area__content__title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 16px;
    font-size: 40px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.88px;
}

.services-area__content__title a {
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.services-area__content__subtitle {
    font-size: 24px;
    font-weight: 500;
    color: #757575;
    line-height: 150%;
    letter-spacing: -0.456px;
}

.services-area__content__carousel {
    margin-top: 24px;
    width: 100%;
    height: 670px;
}